import React from "react"
import { graphql, PageProps } from "gatsby"

import Bio from "../components/Bio"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import TagList from "../components/TagList"

export { Head } from "../functions/Head"

const AllTags: React.FC<PageProps<GatsbyTypes.AllTagsQuery>> = ({ data, location }) => {
  const siteTitle = data.site?.siteMetadata?.title || `Title`

  // TODO ここをきれいにしたい
  var tags: string[] = []
  data.allMarkdownRemark.nodes.forEach(node => {
    node.frontmatter?.tags?.forEach(tag => {
      if (tag !== undefined) {
        tags.push(tag)
      }
    })
  })
  tags = Array.from(new Set(tags)).sort()

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All tags" />
      <h1>All Tags</h1>
      <TagList tags={tags} />
      <hr />
      <Bio />
    </Layout>
  )
}

export default AllTags

export const allTagsQuery = graphql`
  query AllTags {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      nodes {
        frontmatter {
          tags
        }
      }
    }
  }
`